body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

html {
  background-color: black;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  color-scheme: dark;
}

.MuiMenu-list {
  background: black;
  color: white;
}

@media only screen and (max-width: 900px) {
  .infinite-scroll {
    width: 95vw;
  }
}

#app-bar-icons:hover path rect {
  stroke: white;
  fill: none;
}
