/* * {
  outline: 1px solid red;
} */
body {
  background-color: black;
  margin: 0; /* Optional: Remove default body margin */
  padding: 0; /* Optional: Remove default body padding */
}
@font-face {
  font-family: "Test Sohne Schmal";
  src: url("./app/assets/Fonts/TestSohneSchmal/test-soehne-schmal-kraftig.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html {
  scroll-behavior: smooth;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hoverConver:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button.button3 {
  border-radius: 100rem;
  padding: 1rem;
  margin: 1rem 0rem;
  font-family: "IBM Plex Mono";
  padding: 0.4rem 2.7rem;
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #e60a0a;
  text-transform: none;
}

button.gradient-button1 {
  border-radius: 100rem;
  padding: 1rem;
  margin: 1rem 0rem;
  font-size: 14px;
  font-family: "IBM Plex Mono";
  padding: 0.4rem 2.7rem;
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.gradient-button2 {
  border-radius: 100rem;
  padding: 1rem;
  margin: 1rem 0rem;
  font-size: 14px;
  font-family: "IBM Plex Mono";
  padding: 0.4rem 3rem;
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(240deg, #ffffff, #ffffff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}

button.gradient-button3 {
  border-radius: 10rem;
  margin: 1rem 0rem;
  font-family: "IBM Plex Mono";
  padding: 5px 0px 5px 10px;
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.gradient-button4 {
  border-radius: 10rem;
  margin: 1rem 0rem;
  font-family: "IBM Plex Mono";
  padding: 5px 0px 5px 10px;
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(240deg, #ffffff, #ffffff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.gradient-button5 {
  border-radius: 100rem;
  font-family: "IBM Plex Mono";
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.gradient-button6 {
  border-radius: 10rem;
  font-family: "IBM Plex Mono";
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(240deg, #ffffff, #ffffff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.gradient-button7 {
  border-radius: 100rem;
  border: "1px solid white";
  margin: 0.5rem 0rem;
  font-size: 16px;
  font-family: "IBM Plex Mono";
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}

.input-rounded-corners .MuiOutlinedInput-root {
  border-radius: 38px;
  background: rgba(86, 62, 62, 0.35);
  backdrop-filter: blur(65px);
  font-family: "IBM Plex Mono";
}
.input-plain-background6 {
  width: 100%;
  border-radius: 17px;
  background: rgba(0, 0, 0, 0);
  font-family: "IBM Plex Mono";
  color: #ffffff;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-left: 10px;
  padding-right: 40px;
  border-color: #fff;
}

.input-plain-background6.focused::placeholder {
  color: transparent;
}

.input-plain-background6.focused::-webkit-input-placeholder {
  color: transparent;
}

.input-plain-background6.focused:-moz-placeholder {
  color: transparent;
}

.input-plain-background6.focused:-ms-input-placeholder {
  color: transparent;
}

/* Adjust the padding and margin based on your design */
.input-plain-background6.focused {
  padding-top: 20px;
}

.input-plain-background .MuiOutlinedInput-root {
  border-radius: 17px;
  background: rgba(0, 0, 0, 0);
  font-family: "IBM Plex Mono";
  color: #ffffff;
  ::-webkit-scrollbar {
    display: none;
  }
}
.input-plain-background1 .MuiOutlinedInput-root {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  font-family: "IBM Plex Mono";
  color: #ffffff;
  border: 1px solid #e3e1e1;
  ::-webkit-scrollbar {
    display: none;
  }
}

.input-plain-background2 .MuiOutlinedInput-root {
  border-radius: 17px;
  background: rgba(0, 0, 0, 0);
  font-family: "IBM Plex Mono";
  color: #ffffff;
  ::-webkit-scrollbar {
    display: none;
  }
}
.search-plain-background .MuiOutlinedInput-root {
  border-radius: 38px;
  background: #181818;
  font-family: "Poppins";
  color: #ffffff;
  padding: 3px 16px;
}

.input-plain-background::placeholder {
  opacity: 1;
  font-family: "IBM Plex Mono";
  text-align: left;
}
.error {
  color: #f8baba;
  font-size: smaller;
}
input::placeholder {
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-family: "IBM Plex Mono";
}
input {
  text-align: left;
}
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: tomato;
  color: white;
}
.auto-complete {
  color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.profile {
  border-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.68) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-bottom: 2px solid transparent;
  border-image-slice: 1;
}
::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.div-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.square {
  position: relative;
}

.square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square img {
  aspect-ratio: 1/1;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.square-video {
  position: relative;
}

.square-video::after {
  content: "";
  display: block;
}
.square-video video {
  aspect-ratio: 3/4;
  position: absolute;
  width: 100%;
  height: 50%;
  object-fit: cover;
  object-position: 50% 50%;
}

.squareStory img {
  /* aspect-ratio: 16/9; */
  /* position: relative; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 65vh;
  object-fit: fill;
  border-radius: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.squareStory video {
  width: 100%;
  height: 65vh;
  object-fit: fill;
  border-radius: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

/* VideoPlayer.css */

/* Hide all controls except the mute button */
.video-wrapper .video-controls {
  display: none;
}

/* Show the mute button */
.video-wrapper .video-mute-button {
  display: block;
}
button.accept-button {
  height: 35px;
  border-radius: 100rem;
  margin: 1rem 0rem;
  font-family: "IBM Plex Mono";
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.17),
      rgba(233, 0, 255, 1)
    ),
    linear-gradient(240deg, #16e8ff, #e900ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
button.reject-button {
  height: 35px;
  border-radius: 100rem;
  margin: 1rem 0rem;
  font-family: "IBM Plex Mono";
  background-color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 0.2em transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(240deg, #ffffff, #ffffff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
  color: #ffffff;
  text-transform: none;
}
.blurred {
  filter: blur(4px);
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.overlay {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.001)
  );
  z-index: 99999;
  box-sizing: border-box;
  height: 80px;
}

.overlay2 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.001)
  );
  z-index: 99999;
  box-sizing: border-box;
  height: 80px;
}

.overlay3 {
  width: 100%;
  display: flex;
  padding: 10px;
  z-index: 99999;
  box-sizing: border-box;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.media-container {
  width: 100%;
  position: relative;
}

.media-container2 {
  width: 80%;
  position: relative;
}

#app-bar-icons:hover path,
#app-bar-icons:hover line,
#app-bar-icons:hover circle {
  stroke: #ffffff;
}

/* Scrollbar For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); /* Track color */
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}
/* .MuiChip-deleteIcon {
  color: "#FFF";
} */
